import { set } from './just';

import {
	parse,
	safeParse,
	any,
	array,
	boolean,
	check,
	function_,
	number,
	object,
	strictObject,
	looseObject,
	optional,
	picklist,
	pipe,
	string,
	transform,
	union,
	brand,
	pick,
	integer,
	maxValue,
	minValue,
	unknown,
	type InferOutput,
} from 'valibot';

const valibot = {
	parse,
	safeParse,
	any,
	array,
	boolean,
	check,
	function_,
	number,
	object,
	strictObject,
	looseObject,
	optional,
	picklist,
	pipe,
	string,
	transform,
	union,
	brand,
	pick,
	integer,
	maxValue,
	minValue,
	unknown,
};
type Valibot = typeof valibot;
set(window, ['R', 'libs', 'valibot'], valibot);

export {
	parse,
	safeParse,
	any,
	array,
	boolean,
	check,
	function_,
	number,
	object,
	strictObject,
	looseObject,
	optional,
	picklist,
	pipe,
	string,
	transform,
	union,
	brand,
	pick,
	integer,
	maxValue,
	minValue,
	unknown,
};
export type { InferOutput, Valibot };
